let frameWindow;

export function sendMessage ( command, value = {} ){
    if( !frameWindow ) {
        frameWindow = document.getElementById("iframe").contentWindow;
    }

    if( !frameWindow ) {
        return;
    }

    frameWindow.postMessage({
        command,
        value
    }, "*" );
}

export function toBlob ( file ) {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.addEventListener('load', () => {
            resolve(reader.result);
        });
        reader.readAsDataURL( file );
    });
}

export function getHexString ( hex ){
    return ("000000" + hex.toString(16)).slice( -6 );
}

export function file2Blob ( file ){
    return new Promise(( resolve, reject ) => {
        var reader = new FileReader();
        reader.onload = function(e){
            resolve( e.target.result );
        }
        reader.readAsDataURL(file);
    });
}

export function file2Image ( file ) {
    const image = new Image ();
    return file2Blob( file ).then( base64 => {
        image.src = base64;
        return image;
    });
}

export const hostname = getHost();

function getHost (){
	const local = [ "0.0.0.0", "127.0.0.1", "localhost", "dev.sanxiangti.com" ];
	if(local.includes(window.location.hostname)) {
		return "//" + window.location.hostname + ":3001/";
	}
	else {
		return "//v.sanxiangti.com/";
	}
}

export let isPro = false;

export function setPro ( value ){
    isPro = value;
}

export function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    console.log('Query variable %s not found', variable);
}

export function getUrlName ( url ){
    if( !url ) return undefined;
    return url.slice(url.lastIndexOf('/') + 1, url.length);
}