import React from 'react';

const comBase = React.lazy(() => import("../../modules/base"));
const comLight = React.lazy(() => import("../../modules/lighting"));
const comMaterial = React.lazy(() => import("../../modules/material"));
const comPostprocessing = React.lazy(() => import("../../modules/postprocessing"));
const comAnchor = React.lazy(() => import("../../modules/anchors"));
const comAnimation = React.lazy(() => import("../../modules/animations"));
const comSounds = React.lazy(() => import("../../modules/sounds"));
const comParticle = React.lazy(() => import("../../modules/particle"));

export default [
	{
		name: 'base',
		icon: 'icon-base',
		text: '基础',
		component: comBase
	},
	{
		name: 'lighting',
		icon: 'icon-lighting',
		text: '灯光',
		component: comLight
	},
	{
		name: 'material',
		icon: 'icon-material',
		text: '材质',
		component: comMaterial
	},
	{
		name: 'postprocessing',
		icon: 'icon-postprocessing',
		text: '后处理',
		component: comPostprocessing
	},
	// {
	// 	name: 'particle',
	// 	icon: 'icon-lizixitong',
	// 	text: '粒子系统',
	// 	component: comParticle
	// },
	{
		name: 'anchor',
		icon: 'icon-anchor',
		text: '热点',
		component: comAnchor
	},
	{
		name: 'animation',
		icon: 'icon-animation',
		text: '动画',
		component: comAnimation
	},
	// {
	// 	name: 'xr',
	// 	icon: 'icon-xr',
	// 	text: 'AR/VR/MR',
	// 	load (){
	// 		return import("../../modules/xr");
	// 	}
	// },
	{
		name: 'sounds',
		icon: 'icon-sounds',
		text: '声音',
		component: comSounds
	}
];