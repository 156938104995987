import React, { useEffect, useState } from 'react';
import './index.scss';
import { Modal, message, Button } from 'antd';
import 'antd/dist/antd.compact.css';
import debounce from 'lodash/debounce';
import isObject from 'lodash/isObject';
import axios from './utils/axios';
import { getQueryVariable, getUrlName, setPro } from './utils';
import Nav from './components/nav';
import { sendMessage, hostname } from './utils/';
import Preferences from './modules/preferences';
import { SettingOutlined, CameraOutlined } from '@ant-design/icons';
import { setProjectDetail } from './conf/projectDetail';
import pkg from '../package.json';
import { GetXVersion } from './constant';
// import { TimeLineModule } from './modules/Timeline';
import { SceneList } from './modules/SceneList';

console.log("The Editor Version:", pkg.version);

const protocol = window.location.protocol;
const id = window.location.pathname.slice(1);

const format = getQueryVariable('format');
const frameUri = `${protocol}${hostname}${id}?type=cloud&format=${format}`;	//只允许云空间修改

const save = debounce(() => {
	sendMessage("export-scene");
}, 500);

const back = debounce(() => {
	window.location.href = 'https://www.sanxiangti.com/drive/my';
}, 500);

const BasisMimeType = 'image/basis';

const App = () => {

	const [ gl, setGL ] = useState();
	const [ isPreferencesVisible, setIsPreferencesVisible ] = useState( false );
	const [ saving, setSaving ] = useState( false ); 

	useEffect(() => {

		//权限校验
		axios.get('/api/user/authority', {
			type: format == 'pano' ? 1 : 2	//1全景图，2模型
		}).then(({ data }) => {
			setPro( data )
		});

		window.addEventListener("message", onMessage);

		function onMessage ( e ) {
			let { command, value } = e.data;

			switch( command ) {
				case "__internal_data_sync": {
					
					if( !value ) return;

					setGL( value );
					
					break;
				}	
				case "screenshot": {
					message.success("封面保存成功！");
					setSaving( false );
					sendMessage("export-scene");
					break;		
				}
				//加载完毕
				case "DONE": {
					sendMessage("get-project-detail");
					// initTextureManage();
					break;
				}
				case "export-scene": {
					let { extras, gltf, textures } = value;
					//extras -> main file 保存配置文件
					//gltf -> xxx.gz 覆盖gltf文件
					let { MajorVersion, MinorVersion, Reversion } = GetXVersion( gltf.asset.generator );

					//X引擎版本大于等于2.0.21, 导出时新增basis支持
					if( MajorVersion >= 2 && MinorVersion >= 0 && Reversion >= 21 ) {

						console.log('Basis support');
						if( gltf.images && gltf.images.length > 0 ) {

							let hasTexture = Array.isArray( textures ) && textures.length > 0;
							if( !hasTexture ) return;

							//过滤没有basis的纹理
							let basises = textures.filter( texture => {
								return texture.basis;
							}).map( texture => {
								return {
									mimeType: BasisMimeType,
									uri: texture.basis,
									name: texture.name
								}
							});

							gltf.images = gltf.images.concat( basises );

							// 找到index对应的texture
							gltf.textures.forEach( texture => {

								gltf.images.forEach((image, index) => {

									if( !texture.name ) {
										texture.name = getUrlName(gltf.images[ texture.source ].uri);
									}

									if( !image.name ) {
										image.name = getUrlName( image.uri );
									}

									if( image.name === texture.name && image.mimeType === BasisMimeType ) {
										//材质扩展
										if(!isObject(texture.extensions)) {
											texture.extensions = {};
										}

										texture.extensions["KHR_texture_basisu"] = {
											"source": index
										};
									}
								});
							});
						}
					} else {
						console.warn('Basis not support', MajorVersion + '.' + MinorVersion + '.' + Reversion);
					}

					var updateAddress = '/api/cloud/main-file';
					axios.post(updateAddress, {
						id,
						content: JSON.stringify({ extras, gltf }),
						files: textures
					}).then( data => {
						setSaving( false );
						if( !data.success ) {
							if( data.statusCode === 401 || data.code === 401 ) {
								message.error("保存失败，请重新登陆.");
								return;
							}
							message.error("保存失败，请联系客服或稍后重试.");
							return;
						}
						message.success("配置保存成功！");
						setIsPreferencesVisible( false );
					}).catch( e => {
						setSaving( false );
						message.error("配置保存失败！请检查网络！");
					});
					break;
				}
				case 'get-project-detail': {
					setProjectDetail( value );
					break;
				}
				default: {
				}
			}
		}
		return () => {
			window.removeEventListener("message", onMessage);
		}
	}, []);

	function screenshot () {
		sendMessage("screenshot");
		sendMessage("save-camera-matrix");
		setSaving( true );
	}

	return (<article className="editor-app">
		<div className="header">
			<div className="header-container">
				<div className="logo">
					<img src="//static.sanxiangti.com/statics/logo/logo-white.png" alt="三向体" width={100} />
				</div>
				<div className="bar">
					{/* <div className="item">
						<QuestionCircleOutlined />
					</div> */}
					<div className="item" onClick={() => {
						setIsPreferencesVisible( true );
					}}>
						<SettingOutlined />
					</div>
				</div>
			</div>
			<div className="operation">
				<Button.Group size="large">
					<Button onClick={back}>退出</Button>
					<Button onClick={() => {
						window.open( frameUri, '_blank');
					}}>预览</Button>
					<Button type="primary" size="large" loading={saving} onClick={() => {
						setSaving( true );
						save();
					}}>保存配置</Button>
				</Button.Group>
			</div>
		</div>
		<div className="container">
			{/* <SceneList /> */}
			<div className="viewer">
				<Button className="screenshot" onClick={screenshot} loading={saving} icon={<CameraOutlined />}>
					保存视角
				</Button>
				<iframe src={frameUri}
					id="iframe" 
					title="三向体" 
					allowFullScreen 
					frameBorder="0">
				</iframe>
				{/* <TimeLineModule /> */}
			</div>
			<Nav gl={gl} />
		</div>
		<Modal visible={isPreferencesVisible} 
			width={800}
			title="偏好设置" 
			okText="保存配置"
			cancelText="取消"
			okButtonProps={{ loading: saving }}
			onCancel={() => {
				setIsPreferencesVisible( false );
			}}
			onOk={() => {
				setSaving( true );
				save();
			}}>
			{ gl && <Preferences frameUri={frameUri} gl={gl} /> }
        </Modal>
	</article>)
}

export default App;
