import React, { useState, Suspense } from 'react';
import classNames from 'classnames';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './index.scss';

const Switch = React.lazy(() => import('./term/switch'));
const Qrcode = React.lazy(() => import('./term/qrcode'));
const Link = React.lazy(() => import('./term/link'));
const Watermark = React.lazy(() => import('./term/watermark'));
const Loading = React.lazy(() => import('./term/loading'));

const Key = {
    "Switch": "switch",
    "Watermark": "watermark",
    "Loading": "loading",
    "Link": "link",
    "Qrcode": "qrcode",
    "AR": "ar",
    "VR": "vr"
}

const menus = [
    {
        "label": "全局设置",
        "children": [
            {
                label: "基础",
                value: Key.Switch
            },
            // {
            //     label: '水印',
            //     value: Key.Watermark
            // },
            {
                label: '加载器',
                value: Key.Loading
            }
            // {
            //     label: '联系人',
            //     value: Key.Link
            // }
        ]
    },
    {
        "label": "分享",
        "children": [
            {
                label: "二维码",
                value: Key.Qrcode
            }
        ]
    },
    // {
    //     "label": "3D",
    //     "children": [
    //         {
    //             label: "增强现实",
    //             value: Key.AR
    //         },
    //         {
    //             label: "虚拟现实",
    //             value: Key.VR
    //         }
    //     ]
    // }
]

const Preferences = ({ gl, frameUri }) => {

    const [ active, setActive ] = useState( "switch" ); 
    
    return (
       <div className="modules module-preferences">
           <div className="left-container">
                <div className="list-item">
                    {menus.map(( menu, index ) => {
                        return <div className="group" key={index}>
                            <div className="sub-head">{menu.label}</div>
                            {menu.children.map( item => {
                                return <div className={classNames({ item: true, active: item.value === active })} 
                                    onClick={() =>{ setActive( item.value ); }}
                                    key={item.value}>
                                    {item.label}
                                </div>
                            })}
                        </div>
                    })}
                </div>
           </div>
           {<div className="right-container">
                <Suspense fallback={<Spin indicator={LoadingOutlined} />}>
                    { active === Key.Switch && <Switch config={gl.global.switch} /> }   
                    { active === Key.Qrcode && <Qrcode config={gl.global.setting} frameUri={frameUri} /> }   
                    { active === Key.Loading && <Loading config={gl.global.setting} /> }   
                    {/* { active === Key.Watermark && <Watermark config={gl.global.setting} /> }   */}
                </Suspense>
            </div>}
       </div>
    )
}

export default Preferences;